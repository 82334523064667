export default [
	{
		path: "/home",
		name: "home",
		component: () => import("@/views/HomeView.vue"),
		meta: {
			description: "HOME",
			requiresAuth: true,
		},
	},
	{
		path: "/perfil",
		name: "perfil",
		component: () => import("@/views/perfil/PerfilView.vue"),
		meta: {
			description: "PERFIL",
			requiresAuth: true,
		},
		children: [
			{
				path: "/perfil/informacoes",
				name: "informacoes",
				component: () => import("@/views/perfil/InformacoesView.vue"),
			},
			{
				path: "/perfil/seguranca",
				name: "seguranca",
				component: () => import("@/views/perfil/SegurancaView.vue"),
			},
			{
				path: "/perfil/solucoes",
				name: "solucoes",
				component: () => import("@/views/perfil/SolucoesView.vue"),
			},
			{
				path: "/perfil/notificacoes-email",
				name: "notificacoesEmail",
				component: () =>
					import("@/views/perfil/NotificacoesEmailView.vue"),
			},
		],
	},
	{
		path: "/central-avisos",
		name: "centralAvisos",
		component: () => import("@/views/CentralAvisosView.vue"),
		meta: {
			description: "MENSAGENS",
			requiresAuth: true,
			requiresPermission: "mensagens",
		},
	},
	{
		path: "/central-ajuda",
		name: "centralAjuda",
		component: () => import("@/views/CentralAjudaView.vue"),
		meta: {
			description: "DENTRAL DE AJUDA",
			requiresAuth: true,
		},
	},
	{
		path: "/suporte",
		name: "suporte",
		component: () => import("@/views/ProtocoloView.vue"),
		meta: {
			description: "SUPORTE",
			requiresAuth: true,
			requiresPermission: "suporte",
		},
	},
	{
		path: "/financeiro",
		name: "financeiro",
		component: () => import("@/views/FinanceiroView.vue"),
		meta: {
			description: "FINANCEIRO",
			requiresAuth: true,
			requiresPermission: "financeiro",
		},
	},
	{
		path: "/gestao",
		name: "gestao",
		component: () => import("@/views/gestao/IndexView.vue"),
		meta: {
			description: "Gestão",
			requiresAuth: true,
			requiresPermission: "gestao",
		},
		redirect: "/home",
		children: [
			{
				path: "/gestao/contratos",
				name: "contratos",
				component: () => import("@/views/gestao/contratos"),
			},
			{
				path: "/gestao/acessos",
				name: "acessos",
				component: () => import("@/views/gestao/acessos"),
			},
			{
				path: "/gestao/usuarios",
				name: "usuarios",
				component: () => import("@/views/gestao/usuarios"),
				meta: { description: "Usuarios" },
			},
			{
				path: "/gestao/auditoria",
				name: "auditoria",
				component: () => import("@/views/gestao/auditoria"),
			},
		],
	},
	{
		path: "/fontes-monitoradas",
		name: "fontesMonitoradas",
		component: () =>
			import("@/views/monitoramentos/FontesMonitoradasView.vue"),
		meta: {
			description: "FONTES MONITORADAS",
			requiresAuth: true,
			//requiresPermission: "financeiro",
		},
	},
];
